let debounce = require('lodash.debounce');
import * as YouTubeIframeLoader from 'youtube-iframe';
import Player from '@vimeo/player';
import videojs, { VideoJsPlayer } from 'video.js';

enum VideoType {
    NATIVE = 'native',
    VIMEO = 'vimeo',
    YOUTUBE = 'youtube',
    BRIGHTCOVE = 'brightcove',
    NONE = 'none'
}

interface VideoControls {
    pauseButton: HTMLButtonElement;
    playButton: HTMLButtonElement;
    muteButton: HTMLButtonElement;
    unmuteButton: HTMLButtonElement;
}

export class HeroVideo {
    imageFallback: HTMLElement;
    video: HTMLIFrameElement | HTMLVideoElement;
    container: HTMLElement;
    content: HTMLElement;
    controls: VideoControls;
    videoWrapper: HTMLElement;
    videoObject: any;
    videoStartTime: number;
    videoEndTime: number;
    endTimeChecker: any;
    breakpoint: number;
    videoEdgeOffset: number;
    videoType: VideoType;
    allowManualPlay: boolean;
    overlay: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
        this.videoEdgeOffset = 0;
        this.videoWrapper = this.container.querySelector('[data-hero-video-wrapper]') as HTMLElement;
        const videoType = this.videoWrapper.getAttribute("data-video-type");
        switch (videoType.toLowerCase()) {
            case VideoType.NATIVE:
                this.videoType = VideoType.NATIVE;
                break;
            case VideoType.YOUTUBE:
                this.videoType = VideoType.YOUTUBE;
                this.videoEdgeOffset = 480; // vertical offset to hide the youtube title and branding
                break;
            case VideoType.VIMEO:
                this.videoType = VideoType.VIMEO;
                break;
            case VideoType.BRIGHTCOVE:
                this.videoType = VideoType.BRIGHTCOVE;
                break;
            default:
                this.videoType = VideoType.NONE;
        }

        this.imageFallback = this.container.querySelector('[data-hero-video-image]') as HTMLElement;
        this.content = this.container.querySelector('[data-hero-video-content]') as HTMLElement;
        this.breakpoint = parseInt(this.container.getAttribute('data-breakpoint'));
        if (this.videoType === VideoType.NONE) {
            // there's no video
            this.switchToImage();
        } else if (window.outerWidth < this.breakpoint) {
            this.switchToImage();
        } else {
            this.initializeVideo();
        }
    }

    initializeVideo() {
        this.video = this.container.querySelector('[data-hero-video]');
        const controls = {
            pauseButton: this.container.querySelector('[data-hero-video-pause]') as HTMLButtonElement,
            playButton: this.container.querySelector('[data-hero-video-play]') as HTMLButtonElement,
            muteButton: this.container.querySelector('[data-hero-video-mute]') as HTMLButtonElement,
            unmuteButton: this.container.querySelector('[data-hero-video-unmute]') as HTMLButtonElement
        }
        this.controls = controls;
        this.overlay = this.container.querySelector('[data-hero-video-overlay]') as HTMLElement;

        // Recalculate video size
        this.setDimensions();
        window.addEventListener('resize', debounce(() => {
            this.setDimensions();
            if (window.outerWidth < this.breakpoint) {
                this.switchToImage();
            }

        }, 50));

        this.videoEndTime = parseInt(this.video.getAttribute('data-video-end')) || null;
        this.videoStartTime = parseInt(this.video.getAttribute('data-video-start')) || 0;
        if (!this.video.src) {
            // Youtube initialization and handlers
            if (this.videoType === VideoType.YOUTUBE) {
                this.initYoutubeVideo();
            }

            // Native video initialization
            if (this.videoType === VideoType.NATIVE) {
                this.initNativeVideo();
            }

            // Vimeo video initialization
            if (this.videoType === VideoType.VIMEO) {
                this.initVimeoVideo();
            }

            // Brightcove video initialization
            if (this.videoType === VideoType.BRIGHTCOVE) {
                this.initBrightcoveVideo();
            }
        }
    }

    initNativeVideo() {
        this.video = this.video as HTMLVideoElement;
        const videoSource = this.video.querySelector('source');
        if (videoSource) {
            videoSource.src = videoSource.getAttribute('data-src');
            this.videoObject = this.video;
            this.videoObject.addEventListener('loadeddata', () => {
                const playPromise = this.videoObject.play();
                if (playPromise !== undefined) {
                    playPromise.then(() => {
                        // Autoplay is working
                    }).catch(error => {
                        // Autoplay is blocked
                        this.autoplayBlocked(true);
                    })
                }
                else this.switchToImage();
            })
            this.videoObject.load();
            this.showVideoAndAttachControls();
        }
    }

    initYoutubeVideo() {
        let videoPlayer;
        YouTubeIframeLoader.load(YT => {
            this.video.src = this.video.getAttribute('data-src');
            videoPlayer = new YT.Player(this.video.id, {
                events: {
                    'onReady': this.ytVideoLoaded.bind(this),
                    'onStateChange': this.ytOnPlayerStateChange.bind(this),
                }
            });
            this.videoObject = videoPlayer;
        });
    }

    initVimeoVideo() {
        this.video.src = this.video.getAttribute('data-src');
        const vimeoPlayer = new Player(this.video, {});
        vimeoPlayer.on('loaded', () => {
            vimeoPlayer.play().then(() => {
                // Video is playing
                vimeoPlayer.setVolume(0).then((volume: number) => {
                    this.showVideoAndAttachControls();
                })
            }).catch(error => {
                // Video had an error or was blocked
                this.autoplayBlocked(true);
            });
        });
        this.videoObject = vimeoPlayer;
    }

    initBrightcoveVideo() {
        const videoId = this.video.getAttribute('data-video-id');
        const playerId = this.video.id;

        const brightcovePlayer = videojs.getPlayer(playerId);
        if (brightcovePlayer) {
            brightcovePlayer.ready(function () {
                brightcovePlayer.autoplay('muted');
                brightcovePlayer.catalog.getVideo(videoId, function (error, video) {
                    if (error) {
                        this.autoplayBlocked(true);
                    }
                    else {
                        brightcovePlayer.catalog.load(video);
                    }
                });
            });
            this.showVideoAndAttachControls();
            this.videoObject = brightcovePlayer;
        }
    }

    showVideoAndAttachControls() {
        this.imageFallback.setAttribute('aria-hidden', 'true');
        this.videoWrapper.removeAttribute('aria-hidden');
        this.container.setAttribute('data-hero-video-container', 'video');
        this.AttachControls();
    }

    AttachControls() {
        this.controls.pauseButton.classList.remove('hide');
        this.controls.pauseButton.addEventListener('click', this.playPause.bind(this));
        this.controls.playButton.addEventListener('click', this.playPause.bind(this));
        this.overlay.addEventListener('click', this.playPause.bind(this));

        if (this.controls.unmuteButton) {
            this.controls.unmuteButton.classList.remove('hide');
            this.controls.muteButton.addEventListener('click', this.muteUnmute.bind(this));
            this.controls.unmuteButton.addEventListener('click', this.muteUnmute.bind(this));
        }
    }

    playPause() {
        // Youtube Play and Pause
        if (this.videoType === VideoType.YOUTUBE) {
            const playerState = this.videoObject.getPlayerState();
            // == 1 means it's playing
            if (playerState == 1) {
                this.videoObject.pauseVideo();
                this.setControlVisibility(this.controls.pauseButton, this.controls.playButton);
            }
            else {
                this.videoObject.playVideo();
                this.setControlVisibility(this.controls.playButton, this.controls.pauseButton);
            }
        }

        // Native Play and Pause
        if (this.videoType === VideoType.NATIVE) {
            if (this.videoObject.paused) {
                this.videoObject.play();
                this.setControlVisibility(this.controls.playButton, this.controls.pauseButton);
            }
            else {
                this.videoObject.pause();
                this.setControlVisibility(this.controls.pauseButton, this.controls.playButton);
            }
        }

        // Vimeo Play and Pause
        if (this.videoType === VideoType.VIMEO) {
            this.videoObject.getPaused().then((paused: boolean) => {
                if (paused) {
                    this.videoObject.play();
                    this.setControlVisibility(this.controls.playButton, this.controls.pauseButton);
                }
                else {
                    this.videoObject.pause();
                    this.setControlVisibility(this.controls.pauseButton, this.controls.playButton);
                }
            })
        }

        // Brightcove Play and Pause
        if (this.videoType === VideoType.BRIGHTCOVE) {
            if (this.videoObject.paused()) {
                this.videoObject.play();
                this.setControlVisibility(this.controls.playButton, this.controls.pauseButton);
            }
            else {
                this.videoObject.pause();
                this.setControlVisibility(this.controls.pauseButton, this.controls.playButton);
            }
        }
    }

    muteUnmute() {
        // Youtube Mute and Unmute
        if (this.videoType === VideoType.YOUTUBE) {
            const isMuted = this.videoObject.isMuted();
            if (isMuted) {
                this.videoObject.unMute();
                this.setControlVisibility(this.controls.unmuteButton, this.controls.muteButton);
            }
            else {
                this.videoObject.mute();
                this.setControlVisibility(this.controls.muteButton, this.controls.unmuteButton);
            }
        }

        // Native Mute and Unmute
        if (this.videoType === VideoType.NATIVE) {
            if (this.videoObject.muted) {
                this.videoObject.muted = false;
                this.setControlVisibility(this.controls.unmuteButton, this.controls.muteButton);
            }
            else {
                this.videoObject.muted = true;
                this.setControlVisibility(this.controls.muteButton, this.controls.unmuteButton);
            }
        }

        // Vimeo Mute and Unmute
        if (this.videoType === VideoType.VIMEO) {
            this.videoObject.getVolume().then((volume: number) => {
                if (volume > 0) {
                    this.videoObject.setVolume(0).then(volume => {
                        this.setControlVisibility(this.controls.muteButton, this.controls.unmuteButton);
                    })
                }
                else {
                    this.videoObject.setVolume(1).then(volume => {
                        this.setControlVisibility(this.controls.unmuteButton, this.controls.muteButton);
                    })
                }
            })
        }

        // Brightcove Mute and Unmute
        if (this.videoType === VideoType.BRIGHTCOVE) {
            if (this.videoObject.muted()) {
                this.videoObject.muted(false);
                this.setControlVisibility(this.controls.unmuteButton, this.controls.muteButton);
            }
            else {
                this.videoObject.muted(true);
                this.setControlVisibility(this.controls.muteButton, this.controls.unmuteButton);
            }
        }
    }

    setDimensions() {
        const headerWidth = this.container.offsetWidth;
        const headerHeight = this.container.offsetHeight;
        this.video.style.width = `${Math.max(headerWidth, (headerHeight * 16 / 9))}px`;
        this.video.style.height = `${Math.max((headerHeight + this.videoEdgeOffset), (headerWidth * 9 / 16))}px`;
    }

    autoplayBlocked(allowManualPlay?: Boolean, isYouTube?: Boolean) {
        if (isYouTube) {
            this.ytSetDuration();
        }

        if (allowManualPlay) {
            this.switchToImage();
            this.imageFallback.addEventListener('click', this.imageFallbackPlayback.bind(this));
        } else {
            this.switchToImage();
        }
    }

    imageFallbackPlayback() {
        this.showVideoAndAttachControls();
        this.playPause();
    }

    switchToImage() {
        this.imageFallback.removeAttribute('aria-hidden');
        this.videoWrapper.setAttribute('aria-hidden', 'true');
        this.container.setAttribute('data-hero-video-container', 'image');

        if (this.content) {
            this.content.setAttribute('data-hero-no-video', '');
        }
    }

    setControlVisibility(thingToHide: HTMLElement, thingToShow: HTMLElement) {
        thingToHide.classList.add('hide');
        thingToShow.classList.remove('hide');
        thingToShow.focus();
    }

    ytVideoLoaded() {
        if (this.videoObject) {
            const playerState = this.videoObject.getPlayerState();
            if (playerState === 5) {
                // autoplay is blocked abort abort
                this.autoplayBlocked(true, true);
            }
            else {
                this.ytSetDuration();
                this.showVideoAndAttachControls();
            }
        }
    }

    ytSetDuration() {
        const duration = this.videoObject.getDuration();
        this.videoEndTime = this.videoEndTime ? Math.min(this.videoEndTime, duration) : duration;
        this.videoStartTime = this.videoEndTime > this.videoStartTime ? this.videoStartTime : 0;
    }

    ytSetTimeChecker() {
        if (this.videoObject) {
            const playerState = this.videoObject.getPlayerState();
            if (playerState === 1) { // playing
                clearInterval(this.endTimeChecker);
                this.endTimeChecker = setInterval(this.ytCheckForEnd.bind(this), 1000);
            }
            else if (playerState === 0) { // ended
                this.videoObject.seekTo(this.videoStartTime);
                this.videoObject.playVideo();
            }
            else {
                clearInterval(this.endTimeChecker);
            }
        }
    }

    ytCheckForEnd() {
        const curTime = this.videoObject.getCurrentTime();
        if (curTime >= this.videoEndTime) {
            this.videoObject.seekTo(this.videoStartTime);
        }
    }

    ytOnPlayerStateChange(e) {
        this.ytSetTimeChecker();
    }
}