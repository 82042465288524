import { forEach } from 'lodash';
import { nodeListToArray } from '../helpers/nodeListToArray';


export function stickyHeader() {
    window.addEventListener('load', function () {

        const header = document.querySelector('#nav-container') as HTMLElement;
        const subheader = document.querySelector('#subnav-container') as HTMLElement;
        const stickyElement = document.querySelector('data-sticky-element');
        const totalNav = header.offsetHeight + subheader.offsetHeight;
        const main = document.getElementById('main');
        const elementsToOffset = nodeListToArray(document.querySelectorAll('[data-header-offset]'));
        let headerDimensions = header.offsetHeight - header.offsetHeight;
        let lastScrollTop = 0;



        function adjustStickyDependentElements() {
            if (elementsToOffset) {
                elementsToOffset.forEach((el) => {
                    el.style.top = header.offsetHeight.toString() + 'px';
                })
            }
        }

        // run on page load
        adjustStickyDependentElements();


        function stickyDisplayHandler() {
            var currentPos = window.pageYOffset || document.documentElement.scrollTop;

            headerDimensions = header.offsetHeight;

            // handle header sticky
            if (currentPos === 0) {
                if (!document.body.classList.contains('locked')) {
                    header.classList.add('sticky-show');
                    header.style.top = '0px';
                    header.classList.remove('sticky-top');
                }
            }
            // set current position to lastScrollTop
            lastScrollTop = currentPos;
        }

        window.addEventListener('resize', () =>
            window.requestAnimationFrame(() => {
                stickyDisplayHandler();
                adjustStickyDependentElements();

            })
        );

        window.addEventListener('scroll', () =>
            window.requestAnimationFrame(() => {
                stickyDisplayHandler();
            })
        );
    });
}