export class toggleContainer {
    container: HTMLElement;
    trigger: HTMLElement;
    panel: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container as HTMLElement;
        this.trigger = container.querySelector('[data-toggle-trigger]') as HTMLElement;
        this.toggleContainer();
    }

    toggleContainer() {
        this.trigger.addEventListener('click', () => {
            this.panel = this.container.querySelector('[data-toggle-panel]') as HTMLElement;
            this.container.classList.toggle('active');
            if (this.panel.getAttribute('aria-expanded') === 'true') {
                this.panel.setAttribute('aria-expanded', 'false');
            }
            else {
                this.panel.setAttribute('aria-expanded', 'true');
            }
        })
    }
}
