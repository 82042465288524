export const donationBlock = () => {
    const currencyNumberRegex = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
    const donationRadioAmounts = document.querySelectorAll('.donation-amount-list-js') as NodeListOf<Element>;
    let customDonationAmount = document.querySelector('.custom-amount-js') as HTMLInputElement;
    const originalDonationLink = document.querySelector('.donation-link-js')?.getAttribute('href');
    const donateButton = document.querySelector('.donation-link-js') as HTMLAnchorElement;
    const donateButtonText = donateButton?.innerText ?? 'Donate';
    const customAmountMessage = document.querySelector('.custom-amount-message-js');
    const emptyAmountMessage = document.querySelector('.empty-amount-message-js');

    const setQueryStringOn = (element: HTMLAnchorElement, baseUrl: string, fields: URLSearchParams) =>
        element.href = baseUrl + "?" + fields.toString();

    const isAmountValid = (amount: number) => !isNaN(amount)
        && amount >= 5
        && amount.toString().match(currencyNumberRegex);

    const updatePrimaryButtonText = (amount: string) =>
        donateButton.innerText = (donateButtonText + ` ${amount !== '' ? '$'+amount : ''}`).trim();

    const updatePrimaryButtonHrefWithAmount = (amount: string | Number) =>
        setQueryStringOn(
            donateButton as HTMLAnchorElement,
            originalDonationLink,
            (amount == '' || amount == 0) ?
                new URLSearchParams() :
                new URLSearchParams([['amount', amount.toString()]])
        );

    const update = (amount: number) => {
        const isValid = isAmountValid(amount);

        if (isValid) {
            donateButton.classList.remove('disabled');
            donateButton.removeAttribute('disabled');

            updatePrimaryButtonText(amount.toString());
            updatePrimaryButtonHrefWithAmount(amount);

            customAmountMessage.classList.add('hide');
        } else {
            donateButton.classList.add('disabled');
            donateButton.setAttribute('disabled', 'disabled');

            updatePrimaryButtonText('');
            updatePrimaryButtonHrefWithAmount('');

            customAmountMessage.classList.remove('hide');
        }

        if (isNaN(amount))
            emptyAmountMessage.classList.remove('hide');
        else
            emptyAmountMessage.classList.add('hide');
    }

    // Handles pre-populated custom amount from CMS
    const prefilledCustomAmount = parseInt(customDonationAmount?.value);
    if(!isNaN(prefilledCustomAmount))
        update(prefilledCustomAmount);

    donationRadioAmounts?.forEach(donation => {
        donation.addEventListener('click', (e) => {
            customDonationAmount.value = '';
            const donationAmount = parseInt((<HTMLInputElement>event.target).value);
            update(donationAmount);
        })
    });

    donateButton?.addEventListener('click', (e) => {
        if (donateButton.getAttribute('href') == originalDonationLink) {
            donateButton.removeAttribute('href');
            emptyAmountMessage.classList.remove('hide');
        }
    });

    customDonationAmount?.addEventListener('keyup', (e) => {
        const amount = parseInt((e.target as HTMLInputElement).value);
        update(amount);
        donationRadioAmounts.forEach(radio => {
            (<HTMLInputElement>radio).checked = false;
        });
    });
}