import axios from 'axios';
import { currencyFormatter } from '../../helpers/currencyFormatter';

export class GiftCertificateHistoryPage {
    balanceCheckTextBox: HTMLInputElement
    balanceCheckButton: HTMLButtonElement
    balanceCheckResults: HTMLElement
    errormsg: string
    balanceCheckTextBoxEvents = ['keyup', 'change'];
    balanceCheckAPIRoute = "/ace-api/gift/getinfo?gcnum="

    constructor() {
        this.balanceCheckTextBox = document.querySelector("#gc-balancecheck") as HTMLInputElement;
        this.balanceCheckButton = document.querySelector("#gc-balancecheck-button");
        this.balanceCheckResults = document.querySelector("#gc-balancecheck-results");
        this.errormsg = (this.balanceCheckResults) ? this.balanceCheckResults.dataset["errormsg"] : "There was an error getting the balance of this gift certificate";

        if (this.balanceCheckButton && this.balanceCheckTextBox) {
            this.balanceCheckButton.addEventListener('click', this.checkBalance)
            this.balanceCheckTextBoxEvents.forEach(event => {
                this.balanceCheckTextBox.addEventListener(event, () => {
                    this.toggleFieldDisability();
                });
            });
        }

    }

    checkBalance = (e): void => {
        let gcNum = this.balanceCheckTextBox.value;
        if (!gcNum) {
            return;
        }

        let gcInfo = this.getGiftCertificateInfo(gcNum);
        gcInfo.then((result) => {
            this.updateDisplay(result);
        }).catch((error) => {
            this.balanceCheckResults.innerText = this.errormsg;
            this.toggleDisplayVisiblity(this.errormsg);
            console.log(error);
        })
    }

    updateDisplay = (gcInfo: object): void => {
        if (gcInfo) {
            let result = "";
            if (gcInfo["recipientName"])
                result = `
           <div class="stack-sm">
           <p class="large"><span class="bold">Recipient: </span>  ${gcInfo["recipientName"]}</p> 
           <p class="large"><span class="bold">Current Balance: </span> ${currencyFormatter(gcInfo["balance"])}</p>
           </div>`
            else if (gcInfo["balance"])
                result = `
            <p class="large">
            <span class="bold">Current Balance: </span> ${currencyFormatter(gcInfo["balance"])}
            </p>`
            else
                result = this.errormsg;
            this.balanceCheckResults.innerHTML = result;
            this.toggleDisplayVisiblity(result);
        }
    }

    toggleDisplayVisiblity = (result: string) => {
        const resultHasValue = result !== "";
        if (resultHasValue) {
            setTimeout(() => {
                this.balanceCheckResults.focus()
            }, 100);
        }
        return (resultHasValue) ? this.balanceCheckResults.classList.remove("hide") : this.balanceCheckResults.classList.add("hide");
    }

    toggleFieldDisability = () => {
        return (this.balanceCheckTextBox.value !== "") ? this.balanceCheckButton.disabled = false : this.balanceCheckButton.disabled = true;
    }

    private async getGiftCertificateInfo(gcNum: string) {
        const url = this.balanceCheckAPIRoute + gcNum;
        const response = await fetch(url);
        let data = await response.json();
        return data;
    }

}