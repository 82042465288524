export function initSubmitLoadingSpinners() {
    const submitButtons = document.querySelectorAll('button[data-spin-on-submit]');

    for (let i = 0; i < submitButtons.length; i++) {
        initLoadingSpinner(submitButtons[i] as HTMLButtonElement);
    }
}

function initLoadingSpinner(submitButton: HTMLButtonElement) {
    const associatedForm = submitButton.closest('form');
    if (!associatedForm) return;

    associatedForm.addEventListener('submit', () => {
        submitButton.disabled = true;
        submitButton.classList.add('btn-spinning');
    });
}

export function removeLoadingSpinner(submitButton: HTMLButtonElement) {
    submitButton.disabled = false;
    submitButton.classList.remove('btn-spinning');
}