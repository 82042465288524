import { lockBody, unlockBody } from '../helpers/lockBody';

// Static strings
const openMenuClasses = ['theme-dark', 'nav-menu-is-open'];
const navLoadedClass = 'nav-has-loaded';
const menuSlideStateAttribute = 'data-nav-is-showing';
const navId = "nav-container";
const subNavId = "subnav-container";
const menuId = "nav-menu";

class MainNavigation {
    container: HTMLElement;
    subnavContainer: HTMLElement;
    menuTrigger: HTMLButtonElement;
    menuPanel: HTMLElement;
    menuFocusableChildren: Array<HTMLButtonElement | HTMLAnchorElement | HTMLInputElement>;
    menuIsOpen: boolean;
    navIsShowing: boolean;
    existingBodyStyles: string;
    lastScrollPosition: number;
    hasLoaded: boolean;
    currentNavHeight: number;
    bodyEl: HTMLBodyElement;
    onPageAnchorLinks: HTMLAnchorElement[];
    navSearchCloseBtn: null | HTMLElement;
    subnavDisabledPage: boolean;
    constructor(navigation: HTMLElement, subnavigation: HTMLElement) {
        this.container = navigation;
        this.subnavContainer = subnavigation;
        this.menuTrigger = this.container.querySelector(`[aria-controls="${menuId}"]`) as HTMLButtonElement;
        this.menuPanel = document.querySelector(`#${menuId}`) as HTMLElement;
        this.menuFocusableChildren = [].slice.call(this.menuPanel.querySelectorAll('button, a, input'));
        this.bodyEl = document.body as HTMLBodyElement;
        this.menuIsOpen = false;
        this.navIsShowing = true;
        this.hasLoaded = false;
        this.existingBodyStyles = '';
        this.navSearchCloseBtn = null;
        this.subnavDisabledPage = false;
        // Intercept on-page links so that the offset recalculates before the browser reaches it
        // This makes sure that the target is always comfortably visible below the nav
        this.onPageAnchorLinks = [].slice.call(document.querySelectorAll('a[href^="#"]'));
        this.onPageAnchorLinks.forEach(link => {
            link.addEventListener('click', (e: Event) => {
                e.preventDefault();
                const hash = link.getAttribute('href');
                const target = document.querySelector(hash) as HTMLElement;
                if (target) {
                    if (this.navIsShowing && target.offsetTop > window.pageYOffset) {
                        this.slideUp();
                    }
                    else if (!this.navIsShowing && target.offsetTop < window.pageYOffset) {
                        this.slideDown();
                    }
                    setTimeout(() => {
                        target.scrollIntoView(true);
                        target.focus();
                        window.location.hash = hash;
                    }, 0);
                }
            })
        })

    }

    init = () => {
        this.menuTrigger.addEventListener('click', () => {
            if (this.menuIsOpen) {
                this.closeMenu();
            }
            else {
                this.openMenu();
            }
        });

        if (document.querySelector('[data-subnav-disabled')) {
            this.subnavDisabledPage = true;
        }

        document.body.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.which == 27 && this.menuIsOpen) { // Escape key
                this.closeMenu();
                this.menuTrigger.focus();
            }
            if (e.which == 9) { // Tab key. Show nav if tabbed into, or close menu if tabbed out of.
                const focusInNav = this.container.contains(document.activeElement);
                if (focusInNav && !this.navIsShowing) {
                    this.slideDown();
                }
                if (!focusInNav && this.menuIsOpen) {
                    this.closeMenu();
                }
            }
        });

        // Setup on page load
        this.closeMenu();
        this.setPageOffset();
        this.slideDown();
    }

    slideDown = () => {
        if (!this.subnavDisabledPage) {
            this.subnavContainer.setAttribute(menuSlideStateAttribute, 'true');
        }
        this.navIsShowing = true;
        this.setCssVariables();
    }

    slideUp = () => {
        if (!this.subnavDisabledPage) {
            this.subnavContainer.setAttribute(menuSlideStateAttribute, 'false');
        }
        this.navIsShowing = false;
        this.setCssVariables();
    }

    openMenu = () => {

        this.menuPanel.setAttribute('aria-hidden', 'false');
        this.menuTrigger.setAttribute('aria-expanded', 'true');
        this.enableTabbableChildren();
        this.menuIsOpen = true;
        this.existingBodyStyles = lockBody();
        openMenuClasses.forEach(cssClass => {
            this.container.classList.add(cssClass);
        });
    }

    closeMenu = () => {
        this.menuPanel.setAttribute('aria-hidden', 'true');
        this.menuTrigger.setAttribute('aria-expanded', 'false');
        this.disableTabbableChildren();
        this.menuIsOpen = false;
        unlockBody(this.existingBodyStyles);
        openMenuClasses.forEach(cssClass => {
            this.container.classList.remove(cssClass);
        });
        this.setPageOffset();
    }

    enableTabbableChildren = () => {
        this.menuFocusableChildren.forEach(child => {
            child.tabIndex = 0;
        })
    }

    disableTabbableChildren = () => {
        this.menuFocusableChildren.forEach(child => {
            child.tabIndex = -1;
        })
    }

    setPageOffset = () => {
        if (!this.hasLoaded) {

            this.container.classList.add(navLoadedClass);

            if (!this.subnavDisabledPage) {
                this.subnavContainer.classList.add(navLoadedClass);
            }
            this.hasLoaded = true;
        }
        this.currentNavHeight = this.container.offsetHeight;
        this.setCssVariables();
    }

    scrollHandler = () => {
        if (this.hasLoaded) {
            const currentPosition = window.pageYOffset;
            const delta = currentPosition - this.lastScrollPosition;
            this.lastScrollPosition = currentPosition;
            if (this.navIsShowing) {
                if (delta > 0 && currentPosition > this.currentNavHeight && !this.menuIsOpen) {
                    this.slideUp();
                }
            }
            else if (delta < 0 || currentPosition < this.currentNavHeight) {
                this.slideDown();
            }
        }
    }

    resizeHandler = () => {
        this.setPageOffset();
    }

    setCssVariables = () => {
        document.documentElement.style.setProperty('--nav-clearance', `${this.navIsShowing ? this.currentNavHeight + this.subnavContainer.offsetHeight : this.currentNavHeight}px`);
        document.documentElement.style.setProperty('--nav-margin-offset', `${this.navIsShowing ? this.currentNavHeight : 0}px`);
        if (!this.subnavDisabledPage) {
            document.documentElement.style.setProperty('--total-nav-height', `${this.currentNavHeight + this.subnavContainer.offsetHeight}px`);
        }
        else {
            document.documentElement.style.setProperty('--total-nav-height', `${this.currentNavHeight}px`);
            // If PDP page, update variable once anchor nav has initialized 
            setTimeout(() => {
                document.documentElement.style.setProperty('--pdp-nav-clearance', `${(document.getElementById('pdpNav')) ? document.getElementById('pdpNav').offsetHeight + this.currentNavHeight : 0}px`);
            }, 1000)
        }
    }

    get isTheMenuOpen() {
        return this.menuIsOpen;
    }
}

let mainNavigation: MainNavigation = null;
const navContainer = document.querySelector(`#${navId}`) as HTMLElement;
const subNavContainer = document.querySelector(`#${subNavId}`) as HTMLElement;
if (navContainer && subNavContainer) {
    mainNavigation = new MainNavigation(navContainer, subNavContainer);
    (window as any).mainNavigation = mainNavigation;
}

export { mainNavigation };