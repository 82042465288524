export class MediaListener {
    mediaElement: HTMLMediaElement;

    constructor(mediaElement: HTMLMediaElement) {

        mediaElement.addEventListener('play', () => {

            let allMediaElements = Array.prototype.slice.call(document.querySelectorAll('audio, video')) as HTMLMediaElement[];
            allMediaElements.forEach((el) => {
                if (el != mediaElement) {
                    el.pause();
                }
            })
        })
    }
}
