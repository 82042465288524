

export class DonationHistoryPage {
    fiscalYearTrigger: HTMLButtonElement
    fiscalYearPanel: HTMLElement
    CalendarYearTrigger: HTMLButtonElement
    CalendarYearPanel: HTMLElement
    AllYears: NodeListOf<HTMLElement>

    hiddenClass = 'visually-hidden';

    constructor() {
        this.fiscalYearTrigger = document.querySelector("[data-fiscal-year-trigger]") as HTMLButtonElement;
        this.fiscalYearPanel = document.querySelector("[data-fiscal-year-listing]")
        this.CalendarYearTrigger = document.querySelector("[data-calendar-year-trigger]") as HTMLButtonElement;
        this.CalendarYearPanel = document.querySelector("[data-calendar-year-listing]")

        this.AllYears = document.querySelectorAll("[data-year-container]")

        this.initYearDisplayToggle()
        this.initAccordions()
    }

    initYearDisplayToggle = () => {
        if (!this.fiscalYearTrigger || !this.fiscalYearTrigger || !this.CalendarYearTrigger || !this.CalendarYearPanel)
            return

        this.fiscalYearTrigger.addEventListener('click', (e) => {
            if (this.fiscalYearTrigger.disabled)
                return

            this.fiscalYearTrigger.disabled = true
            this.fiscalYearPanel.classList.remove(this.hiddenClass)
            this.CalendarYearTrigger.disabled = false
            this.CalendarYearPanel.classList.add(this.hiddenClass)
        })

        this.CalendarYearTrigger.addEventListener('click', (e) => {
            if (this.CalendarYearTrigger.disabled)
                return

            this.CalendarYearTrigger.disabled = true
            this.CalendarYearPanel.classList.remove(this.hiddenClass);
            this.fiscalYearTrigger.disabled = false
            this.fiscalYearPanel.classList.add(this.hiddenClass)
        })
    }

    initAccordions = () => {
        if (!this.AllYears || this.AllYears.length < 1)
            return

        this.AllYears.forEach((year) => {
            let trigger = year.querySelector("[data-accordion-trigger]") as HTMLElement
            let panel = year.querySelector("[data-accordion-panel]") as HTMLElement

            trigger.addEventListener('click', (e) => {
                trigger.classList.toggle('active')
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                    trigger.innerHTML = trigger.dataset.closedLabel
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px"
                    trigger.innerHTML = trigger.dataset.openLabel
                }
            })
        })
    }
}
