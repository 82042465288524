import { nodeListToArray } from './helpers/nodeListToArray';
import { vhUnitUpdate, vhUnitInitial } from './helpers/vhUnitFix';
import { SvgSprite } from './modules/svg-loader';
import { Accordion } from './modules/accordion';
import { mainNavigation } from './modules/mainNavigation';
import { BestAvailablePage} from './pages/best-available/best-available';
import { HeroVideo } from './modules/heroVideo';
import throttle = require('lodash.throttle');
import debounce = require('lodash.debounce');
import { RevealPanel } from './modules/revealPanel';
import { MediaListener } from './modules/mediaListener';
import { Gallery } from './modules/gallery';
import { stickyHeader } from './modules/stickyHeader';
import { DonationHistoryPage } from './pages/donationHistoryPage/donationHistoryPage'
import { GiftCertificateHistoryPage } from './pages/giftCertificateHistoryPage/giftCertificateHistoryPage'
import { donationBlock } from './Donation Block/donationBlock';
import { initSubmitLoadingSpinners } from './modules/submitLoadingSpinner';
import { toggleContainer } from './modules/toggleContainer';

// These are needed for the vh fix (to make mobile browsers happy)
let previousPageWidth = 0;
let previousPageHeight = 0;

const init = () => {
    new SvgSprite('/Static/img/adage-sprite.svg');
    navigationInit();
    vhUnitInitial();
    heroInit();
    componentsInit();
    blocksInit();
    bestAvailableInit();
    donationHistoryInit();
    giftcertificateHistoryInit();
    stickyHeader();
    initSubmitLoadingSpinners();
}

const bestAvailableInit = () => { new BestAvailablePage() }

const donationHistoryInit = () => { new DonationHistoryPage() }

const giftcertificateHistoryInit = () => { new GiftCertificateHistoryPage() }

const heroInit = () => {
    // Hero videos
    const heroVideoContainers = nodeListToArray(document.querySelectorAll('[data-hero-video-container]')) as HTMLElement[];
    heroVideoContainers.forEach(heroVideoContainer => {
        new HeroVideo(heroVideoContainer);
    });
}

const navigationInit = () => {
    if (mainNavigation) mainNavigation.init();

    // Setup for determining vh unit size
    previousPageWidth = window.innerWidth;
    previousPageHeight = window.innerHeight;

    document.addEventListener('scroll', throttle(() => {
        if (mainNavigation) mainNavigation.scrollHandler();
    }, 10));
    window.addEventListener('resize', debounce(() => {
        if (mainNavigation) mainNavigation.resizeHandler();
        resizeVhUpdates();
    }, 50));
}

const blocksInit = () => {
    // Accordion Init
    let accordions = new Accordion({
        oneOpenAtATime: true
    });

    // Gallery Init
    let galleryClass = 'single-item-gallery';
    let galleries = nodeListToArray(document.querySelectorAll('[data-gallery]'));
    galleries.forEach((gallery, index) => {
        gallery.classList.add(`${galleryClass}-${index}`);
        new Gallery(gallery);
    });

    // DonationBlock Init
    donationBlock();
}

const componentsInit = () => {
    const revealPanelEls = [].slice.call(document.querySelectorAll('[data-panel]')) as Array<HTMLElement>;
    revealPanelEls.forEach(panelEl => {
        new RevealPanel(panelEl.id);
    });
    const mediaElements = Array.prototype.slice.call(document.querySelectorAll('audio, video')) as HTMLMediaElement[];
    mediaElements.forEach(element => {
        new MediaListener(element);
    });

    // Toggle Containers
    const containers = nodeListToArray(document.querySelectorAll('[data-toggle-container]'));
    containers.forEach((e) => {
        new toggleContainer(e);
    })
}


const resizeVhUpdates = () => {
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;

    // Smaller resizes might be on scroll, so this measures if it's safe to treat it as a fully new browser size and recalculate.
    if (Math.abs(previousPageWidth - pageWidth) > 60 || Math.abs(previousPageHeight - pageHeight) > 60) {
        vhUnitInitial();
    }

    // This will get called on scroll-triggered resizes, so can be used for full-height elements that have their own scrollbar.
    // if the body scrollbar is locked, then it requires someone force scrolling at the bottom to change the height at that point
    vhUnitUpdate();
    previousPageWidth = pageWidth;
    previousPageHeight = pageHeight;
}




init();