import { nodeListToArray } from '../../helpers/nodeListToArray';
import { Stepper } from '../../modules/stepper';
import { Modal } from '../../../Ace2/modules/a11y-modal';

interface IStepper {
    id: string;
    stepper: Stepper;
}

export class BestAvailablePage {
    zonesSelector: HTMLSelectElement;
    performanceSelector: HTMLSelectElement;
    selectedZone: string;
    steppers: IStepper[];
    activeZone: HTMLElement[];
    minicart: Array<any>;
    minicartElement: HTMLElement;
    minicartList: HTMLElement | null;
    minicartSubtotal: number;
    bestAvailableModal: any;
    zonesArePresent: boolean;
    wheelchairActivityTypes: HTMLInputElement[];
    wheelchairPriceTypes: HTMLInputElement[];
    constructor() {
        this.zonesSelector = (document.querySelector('[data-zones]') as HTMLSelectElement);
        this.performanceSelector = (document.querySelector('[data-performance]') as HTMLSelectElement);
        this.selectedZone = (this.zonesSelector) ? this.zonesSelector.value : '';
        this.zonesArePresent = (this.zonesSelector) ? true : false;
        this.activeZone = [];
        this.steppers = [];
        this.minicart = [];
        this.minicartElement = document.querySelector("[data-bestavailable-minicart]");
        this.minicartList = null;
        this.minicartSubtotal = 0;
        this.wheelchairActivityTypes = [];
        this.wheelchairPriceTypes = [];
        this.init();
    }
    currencyFormatter(amount:number) {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formatter.format(amount);
    }

    hideAllZones() {
        nodeListToArray(document.querySelectorAll('[data-zone-id]')).forEach(zone => {
            const steppersToReset = this.steppers.filter(stepper => {
                return stepper.id === zone.dataset.zoneId;
            });
            steppersToReset.forEach(stepper => {
                stepper.stepper.reset();
            });
            zone.classList.add('hide');
        });
    }

    showAllZones() {
        nodeListToArray(document.querySelectorAll('[data-zone-id]')).forEach(zone => {
            zone.classList.remove('hide');
        });
    }

    showActiveZone() {
        nodeListToArray(document.querySelectorAll(`[data-zone-id="${this.selectedZone}"]`)).forEach(zone => {
            zone.classList.remove('hide');
            zone.querySelector('[data-total-amount]').innerHTML = `${this.currencyFormatter(0)}`;
        });
    }

    updateZoneChoice(e: Event) {
        this.selectedZone = (e.target as HTMLSelectElement).value;
        this.activeZone = nodeListToArray(document.querySelectorAll(`[data-zone-id="${this.selectedZone}"]`));
        this.hideAllZones();
        this.showActiveZone();
        this.resetMiniCart();
    }

    resetMiniCart() {
        this.minicart = [];
        this.minicartElement.innerHTML = ``;
        this.minicartSubtotal = 0;
        this.buildMiniCartSubtotal();
    }

    calculateZoneAmount(stepper:HTMLElement) {
        const stepperInput = (stepper.querySelector('[data-bestavailable-input]') as HTMLElement);
        const price = parseFloat(stepperInput.dataset.price);
        const amountOfTickets = (stepperInput as HTMLInputElement).valueAsNumber;
        const total = price * amountOfTickets;
        this.updateWheelchairActivityType();
        return total;
    }

    updateWheelchairActivityType() {
        var wheelchairPriceTypeQuantity = 0;
        this.wheelchairPriceTypes = nodeListToArray(document.querySelectorAll('[data-wheelchair-seat="True"]'));
        this.wheelchairPriceTypes.forEach(quantity => {
            if (quantity.valueAsNumber > 0)
                wheelchairPriceTypeQuantity++;
        });
        var wheelchairActivityTypesList = nodeListToArray(document.querySelectorAll('[data-activity-type-list]'));
        wheelchairActivityTypesList.forEach(activityTypeList => {
            if (wheelchairPriceTypeQuantity > 0)
                activityTypeList.classList.remove('hide');
            else
                activityTypeList.classList.add('hide');
        });
        this.wheelchairActivityTypes = nodeListToArray(document.querySelectorAll('[data-activity-type-radio]'));
        this.wheelchairActivityTypes.forEach(radioButton => {
            if (wheelchairPriceTypeQuantity == 0)
                radioButton.checked = false;
        });
        
    }

    updateTotals(stepper: HTMLElement) {
        this.resetMiniCart();
        const totalAmountText = document.querySelector(`#${stepper.getAttribute('data-bestavailable-item-total')}`);
        const total = this.calculateZoneAmount(stepper);
        totalAmountText.innerHTML = (!isNaN(total)) ? `${this.currencyFormatter(total)}` : ``;
    }

    updatePerformanceChoice = (e) => {
        const currentLink = window.location.href;
        const url = new URL(currentLink);
        const performanceId = url.searchParams.get("performanceId");
        const targetPerformance = (e.target as HTMLSelectElement).value;
        window.location.href = window.location.href.replace(performanceId, targetPerformance);
    }

    buildMiniCartMarkup() {
        const miniCartList = document.createElement('ul');
        miniCartList.classList.add('ace-minicart-list');
        this.minicartElement.appendChild(miniCartList);
        this.minicartList = miniCartList;
    }

    buildMiniCartSubtotal() {
        document.querySelector('[data-bestavailable-minicart-subtotal]').innerHTML = `${this.currencyFormatter(this.minicartSubtotal)}`;
    }

    toggleCheckOutButtonDisability(disabled: boolean) {
        (document.querySelector('[data-button-handle-checkout]') as HTMLButtonElement).disabled = disabled;
    }

    buildMiniCart() {
        nodeListToArray(document.querySelectorAll('[data-zone-id]:not(.hide)')).forEach(zone => {
            const zoneInputAmount = (zone.querySelector('[data-bestavailable-input]') as HTMLInputElement).valueAsNumber;
            const zoneStepper = (zone.querySelector('[data-stepper]') as HTMLElement);
            const zoneName = zoneStepper.getAttribute('data-zone-name');
            if (zoneInputAmount > 0) {
                const cartItem = {
                    "zoneAmount": zoneInputAmount,
                    "zoneName": zoneName,
                    "zoneCost": this.calculateZoneAmount(zoneStepper),
                }
                this.minicartSubtotal += this.calculateZoneAmount(zoneStepper);
                this.minicart.push(cartItem);
            }
        });
        if (this.minicart.length > 0) {
            this.buildMiniCartMarkup();
            this.minicart.forEach(item => {
                const itemElement = document.createElement('li');
                itemElement.innerHTML = `<span>(${item.zoneAmount}) ${item.zoneName}</span><span class="bold">${this.currencyFormatter(item.zoneCost)}</span>`;
                itemElement.classList.add('ace-minicart-item');
                this.minicartList.appendChild(itemElement);
            });
            this.toggleCheckOutButtonDisability(false);
        }
        else
            this.toggleCheckOutButtonDisability(true);
        this.buildMiniCartSubtotal();
    }

    cancelWheelchairDialog() {
        (document.querySelector('[data-wheelchair-seating-checkbox]') as HTMLInputElement).checked = false;
    }

    init() {
        const steppers: HTMLElement[] = nodeListToArray(document.querySelectorAll('[data-stepper]'));
        steppers.forEach(stepper => {
            const stepperObject = new Stepper(stepper);
            this.steppers.push({
                id: stepper.dataset.stepper,
                stepper: stepperObject
            });
            const upButton = stepper.querySelector('[data-bestavailable-up]');
            const downButton = stepper.querySelector('[data-bestavailable-down]');
            const stepperInput = stepper.querySelector('[data-bestavailable-input]');
            if (stepper.dataset.available === "True") {
                upButton.addEventListener('click', () => {
                    this.updateTotals(stepper);
                    this.buildMiniCart();
                });
                downButton.addEventListener('click', () => {
                    this.updateTotals(stepper);
                    this.buildMiniCart();
                });
                stepperInput.addEventListener('keyup', () => {
                    this.updateTotals(stepper);
                    this.buildMiniCart();
                });
            }
            else {
                upButton.setAttribute('disabled', 'disabled');
                downButton.setAttribute('disabled', 'disabled');
                stepperInput.setAttribute('disabled', 'disabled');
            }
        });
        if (this.zonesArePresent) {
            this.showActiveZone();
        }
        else {
            this.showAllZones();
        }
        //Activate Listeners
        if (this.zonesSelector) {
            this.zonesSelector.addEventListener('change', (e) => {
                this.updateZoneChoice(e);
            });
        }
        if (this.performanceSelector) {
            this.performanceSelector.addEventListener('change', (e) => {
                this.updatePerformanceChoice(e);
            });
        }
        if (document.querySelector('[data-bestavailable-modal]')) {
            //Modal Init 
            this.bestAvailableModal = new Modal(document.querySelector('[data-bestavailable-modal]'));
            this.bestAvailableModal.modal.on('hide', (element, event) => {
                this.cancelWheelchairDialog();
            });
        }
    }
}