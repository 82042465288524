import Splide from '@splidejs/splide';
import Video from '@splidejs/splide-extension-video';
import debounce = require('lodash.debounce');
import videojs, { VideoJsPlayer } from 'video.js';

// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class Gallery {
    videoObject: any;
    gallery: Splide;
    constructor(element: HTMLElement) {
        this.gallery = new Splide(element, {
            accessibility: true,
            drag: true,
            // @ts-ignore
            pagination: true,
            // @ts-ignore
            slideFocus: false,
            speed: 500,
            focus: 0,
            arrows: true,
            autoWidth: true,
            keyboard: 'focused',
            padding: {
                left: '0rem',
                right: '56px'
            },
            breakpoints: {
                1280: {
                    padding: { left: '0', right: '44px' },
                },
                1024: {
                    padding: { left: '0', right: '32px' },
                },
                640: {
                    padding: { left: '0', right: '8px' },
                }
            },
            gap: '0rem',
            video: {
                // setting to true is currently required due to a bug in the splide video extension creating
                // the embed url with an empty "playlist" parameter which break YouTube embed link
                loop: true
            },
            classes: {
                pagination: 'splide__pagination carousel-pagination carousel-pagination-nums',
                page: 'splide__pagination__page carousel-page'
            }
        });
        this.gallery.on('mounted', () => {
            this.updateCaptions();
            this.handleExternalVideo();
            if (this.gallery.root.hasAttribute('data-gallery-click-navigation')) {
                this.updateActiveOnClick();
            }
            if (this.gallery.root.hasAttribute('data-gallery-focus-center')) {
                this.gallery.options.focus = 'center';
            }
        })
        this.gallery.on('move', () => {
            this.pauseAudio();
            this.pauseExternalVideo();
        })

        this.gallery.mount({ Video });
    }

    pauseAudio() {
        const slides = this.gallery.Components.Elements.slides;
        slides.forEach((slide) => {
            if (slide.querySelector('audio')) {
                slide.querySelector('audio').pause();
            }
        })
    }

    updateCaptions() {
        this.gallery.Components.Elements.slides.forEach(slide => {
            const slideImg = slide.querySelector('img') as HTMLImageElement;
            const slideCaption = slide.querySelector('[data-gallery-caption]') as HTMLElement;
            if (slideCaption) {
                if (slideImg.complete) {
                    const imageWidth = slideImg.getBoundingClientRect().width;
                    slideCaption.style.width = `${imageWidth}px`;
                    return false;
                }
                slideImg.onload = () => {
                    const imageWidth = slideImg.getBoundingClientRect().width;
                    slideCaption.style.width = `${imageWidth}px`;
                }
            }
        });
    }

    updateActiveOnClick() {
        this.gallery.Components.Elements.slides.forEach((slide, index) => {
            slide.addEventListener("click", () => {
                this.gallery.go(index);
            })
        })
    }

    handleExternalVideo() {
        const slides = this.gallery.Components.Elements.slides;
        slides.forEach((slide) => {

            if (slide.hasAttribute('data-splide-brightcove')) {
                // Setup BC Videos
                let videoEl = slide.querySelector('video-js');
                const videoId = videoEl.getAttribute('data-video-id');
                const playerId = videoEl.id;

                let brightcovePlayer = videojs.getPlayer(playerId);
                if (brightcovePlayer) {
                    brightcovePlayer.ready(function () {
                        brightcovePlayer.play();
                        brightcovePlayer.catalog.getVideo(videoId, function (error, video) {
                            if (error) {
                                this.autoplayBlocked(true);
                            }
                            else {
                                brightcovePlayer.catalog.load(video);
                            }
                        });
                    });
                }

                let thisVideo = brightcovePlayer;

                // Hide poster, play video
                slide.addEventListener('click', (e) => {
                    let splideVideo = slide.querySelector('.splide__video') as HTMLElement;
                    splideVideo.style.display = "block";
                    thisVideo.play();
                }, { once: true })
            }
        })
    }

    pauseExternalVideo() {
        const slides = this.gallery.Components.Elements.slides;
        slides.forEach((slide) => {
            if (slide.querySelector('video-js')) {
                videojs.getPlayer(slide.querySelector('video-js').id).pause();
            }
        })
    }
}